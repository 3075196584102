/* Hero Section */
.hero-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100vw;
    width: 80%;
    text-align: center;
    position: relative;
    overflow: visible; /* Allows content to expand */
    min-height: 100vh; /* Expands based on content */

}

.hero-text {
    padding: 10vh; /* Default padding */
    word-wrap: break-word;
    overflow-wrap: break-word;
    width: 100%; /* Ensure it uses available space */
    max-width: 80vw; /* Prevent it from getting too wide */
}


/* Hero Content Header */
.hero-content-header {
    text-align: left;
    line-height: 1.2;
}

.hero-header {
    font-size: 4vh;
}

.hero-start {
    font-size: 2.5vh;
    text-decoration: underline;
    color: #333;
    font-weight: normal;
    margin-bottom: 0.1rem;
    line-height: 0.1;
}

/* Section Changing Text */
.hero--section--changing {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: normal; /* Allow text to wrap */
    width: auto; /* Adjust based on content */
    max-width: 80vw; /* Prevent it from getting too wide */
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    vertical-align: bottom;
}

.hero-header em {
    position: relative;
    display: inline-block;
    font-weight: bold;
}

.hero--section--changing.typed-cursor {
    opacity: 1;
}

.custom-hr {
    border: 0;
    height: 0.06rem;
    background-color: #333;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.hero-content-about {
    line-height: 0.2;
}

/* Hero Content Headings */
.hero-content-about .hero-currently,
.hero-content-about .hero-driven,
.hero-content-about .hero-also,
.hero-content-about .hero-loves {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.hero-also a {
    color: #333;
    font-weight: bold;
}

.hero-content-about h3 {
    font-size: 2vh;; /* Adjust text size */
    text-decoration: underline;
    font-weight: normal;
}

.hero-content-about h4 {
    font-size: 2vh;; /* Adjust text size */
    font-style: italic;
    font-weight: normal;
}

.hero-stickers {
    display: flex;
    justify-content: center; /* Center stickers horizontally */
    align-items: center;
    margin-top: 20px;
    width: 100%;
    gap: 15px; /* Space between stickers */
    flex-shrink: 1; /* Default value */
}

.sticker-container {
    text-align: center;
    margin: 0; /* Remove the margin here to control spacing using gap */
    position: relative;
    max-width: 11rem; /* Prevent the stickers from getting too large */
    min-width: 5rem; /* Ensure they don't shrink too much */
}

.sticker {
    width: 100%; /* Ensure sticker fills its container */
    max-width: 100%; /* Ensure it doesn't exceed the container's width */
    cursor: pointer;
    transition: transform 0.3s ease, filter 0.3s ease;
}

.sticker:hover {
    transform: rotate(-10deg);
    filter: brightness(1.2);
}

.sticker-subtitle {
    font-size: 1rem;
    margin-top: 10px;
    color: #555;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.sticker-container:hover .sticker-subtitle {
    opacity: 1;
}

@media (min-width: 1271px) {
    /* Styles for screens wider than 1270px */
    .hero-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 100vw;
        width: 70%;
        text-align: center;
        position: relative;
        padding-bottom: 4vh;
        overflow: hidden;
    }
}

@media (max-width: 1270px) {
    /* Styles for screens smaller than 1270px */
    .hero-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 100vw;
        width: 100%;
        text-align: center;
        position: relative;
        padding-bottom: 4vh;
        overflow: hidden;
    }

    .hero-text {
        padding: 6vh 5vw; /* Reduce padding to fit smaller screens */
    }
}
