/* Portfolio Section */
.projects-section {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    width: 70%;
    text-align: center;
    min-height: 100vh; /* Ensures it takes up the full viewport height */
    overflow: visible;
    padding-left: 10%;
}

/* Header Container with Text and Devpost Icon */
.header-container {
    display: flex;
    justify-content: left;
    margin-bottom: 2vh;
}

/* Text Style */
.text {
    font-size: 2.25vh;
    text-decoration: underline;
    color: #333;
    font-weight: normal;
    margin-bottom: 1.3rem;
    margin-right: 10px;
}

/* Devpost Link Styling */
.devpost-header {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 1.5rem;
    color: black;
}

/* Projects Box Layout */
.projects-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    gap: 20px;
    width: 90%;
    margin-bottom: 2vh;
}

/* 'Also' Projects Section */
.projects-add {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the left */
    justify-content: flex-start;
    gap: 4px; /* Reduced space between items */
    margin-top: 0.5rem; /* Small gap before this section */
}

.projects-add h2,
.projects-add h3 {
    margin: 0; /* Remove default margins */
}

.projects-add h2 {
    font-size: 2.25vh;
    text-decoration: underline;
    color: #333;
    font-weight: normal;
    line-height: 1.7; /* Tighten line height */
}

.projects-add h3 {
    font-size: 2.5vh;
    color: #333;
    font-weight: bold;
    line-height: 1.2; /* Slightly reduce line height for tighter spacing */
}

/* Links and Tags Layout */
.project-link {
    text-decoration: none;
    color: black;
    font-weight: bold;
}

.tags-container {
    display: inline-flex;
    gap: 8px;
    margin-top: 5px;
    margin-left: 10px;
}

/* Tags in the top right */
.tags {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 5px;
    z-index: 15;
}

/* Individual tag */
.tag {
    background: white;
    color: black;
    font-size: 12px;
    text-decoration: underline;
    font-weight: normal;
    font-style: italic;
    padding: 2px;
    border: 1px solid black;
    width: 60px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 16;
}

@media (min-width: 1400px) {
    /* Styles for screens smaller than 1400px */
    .projects-section {
        display: flex;
        flex-direction: column;
        justify-content: center; /* Center content vertically */
        align-items: center; /* Center content horizontally */
        width: 80%;
        text-align: center;
        min-height: 100vh; /* Ensures it takes up the full viewport height */
        overflow: visible;
    }
}

@media (max-width: 1390px) {
    /* Styles for screens smaller than 1400px */
    .projects-section {
        display: flex;
        flex-direction: column;
        justify-content: center; /* Center content vertically */
        align-items: center; /* Center content horizontally */
        width: 85%;
        text-align: center;
        min-height: 100vh; /* Ensures it takes up the full viewport height */
        overflow: visible;
    }
}

@media (max-width: 1326px) {
    /* Styles for screens smaller than 1400px */
    .projects-section {
        display: flex;
        flex-direction: column;
        justify-content: center; /* Center content vertically */
        align-items: center; /* Center content horizontally */
        width: 100%;
        text-align: center;
        min-height: 100vh; /* Ensures it takes up the full viewport height */
        overflow: visible;
        padding-left: 0%;
    }
}