/* Overall SVG */
svg {
    color: #333; /* This would override your specific color */
}

/* Import Montserrat font */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

/* Apply Montserrat font to all elements */
* {
    font-family: 'Montserrat', sans-serif;
}

/* Main container */
.container {
    display: flex;
    height: 100vh; /* Full screen height */
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
}

/* Fixed sidebar */
.sidebar {
    width: 15%; 
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    background: #fff; /* Light gray */
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 2%;
}

.me {
    max-width: 15vh;
}

/* Sidebar List Items */
/* Remove default list styling */
.sidebar ul {
    list-style-type: none; /* Removes bullets */
    padding: 0; /* Removes padding */
    margin: 0; /* Removes margin */
}

/* Sidebar List Items */
.sidebar ul li {
    font-size: 1.5rem; /* Adjust text size */
    font-weight: normal; /* Plain text by default */
    text-decoration: none; /* No underline by default */
    cursor: pointer; /* Make the list item clickable */
    margin: 3vh 0; /* Adds space between list items */
    margin-left: 2vh;
    transition: font-weight 0.3s, text-decoration 0.3s; /* Smooth transition for hover effects */
}

/* Active (currently selected) state */
.sidebar ul li.active {
    font-weight: bold; /* Bold text when active */
    text-decoration: underline; /* Underline when active */
}

/* Hover state */
.sidebar ul li:hover {
    font-weight: bolder; /* Make text bolder when hovered */
}


.footer-text {
    position: fixed;
    top: 90%;
}

/* Header */
.header {
    position: fixed;
    width: 96%;
    top: 2%;
    left: 2%;
    right: 2%;
    display: flex;
    justify-content: space-between; /* Pushes left and right sections apart */
    align-items: flex-start;
}

.header-left {
    display: flex;
    align-items: center;
}

/* Logo size */
.logo {
    max-width: 10vh; /* Adjust size as needed */
}

/* Align header-right to the right */
.header-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Aligns buttons + text to the right */
    text-align: right;
    z-index: 10; /* Ensure it’s above other elements */
}

/* Social icons in a row */
.buttons {
    display: flex;
    gap: 1vh; /* Space between icons */
}

/* Icons */
.header a {
    font-size: 4vh;
    transition: transform 0.3s ease; /* Smooth transition for the rotation */
}

/* Ensure color applies to icons */
.header a svg {
    display: inline-block; /* Ensure the <a> tag has a clickable area */
    color: #333;
    cursor: pointer;
    transition: transform 0.3s ease, filter 0.3s ease;
}

/* Hover effect for icons */
.header a:hover {
    transform: rotate(-15deg) scale(1.1); /* Rotate and enlarge simultaneously */
    transition: transform 0.3s ease-in-out;
}

/* Ensure text appears below icons */
.header-right p {
    margin-top: 0.25vh;
    font-size: 1.5vh;
    color: #333;
}

/* Content area */
.content {
    margin-left: 15%; /* Matches sidebar width */
    margin-top: 2%; /* Adjusted for header height */
    margin-bottom: 2%;
    height: 94%; /* Ensures it fits below the header */
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden; /* Prevents any unwanted overflow */
    padding: 0; /* No extra padding */
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

/* Fade-in animation */
.fade-in {
    opacity: 0;
    animation: fadeInAnimation 0.5s ease-in-out forwards;
    transform: translateY(10px); /* Slight lift effect */
}

/* Keyframes for fade-in */
@keyframes fadeInAnimation {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.unsupported-device {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: black;
    color: white;
    font-size: 2rem;
    text-align: center;
}
