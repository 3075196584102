/* Project card */
.project-card {
    position: relative;
    width: 40vh;  /* Adjust the width as needed */
    height: 25vh;   /* Keeping your original height */
    overflow: hidden;
    background-size: contain; /* Ensures the image is stretched to fit the card */
    background-position: center;
    text-decoration: none;
    display: flex;
    align-items: flex-end;
    padding: 0; /* Remove padding from the card */
    transition: transform 0.3s ease, filter 0.3s ease;
    border: 1px solid black;
}

/* Hover effect on the card */
.project-card:hover {
    transform: scale(1.02);
}

/* Background image styling */
.background-image {
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-position: center;
    display: block; /* Ensures no extra space or border around the background */
}

/* Tags in the top right */
.tags {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 5px;
    z-index: 15; /* Ensure tags are above the card hover effect */
}

/* Individual tag */
.tag {
    background: white;
    color: black;
    font-size: 12px;
    text-decoration: underline;
    font-weight: normal;
    font-style: italic;
    padding: 2px;
    border: 1px solid black;
    width: 60px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 16; /* Ensure tags are above card */
}

/* Project title and link icon */
.project-title {
    position: absolute;
    bottom: 14px;  /* Position title near the bottom */
    left: 10px;    /* Position title near the left */
    font-size: 20px;
    font-weight: bold;
    color: white;
    z-index: 20;  /* Ensure title is above description and hover effect */
    margin: 0;     /* Remove default margin */
}

/* Hovered description */
.description {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    font-size: 16px; /* Increased font size for better readability */
    background: rgba(0, 0, 0, 0.6); /* Darker background for higher contrast */
    opacity: 1;
    padding: 15px;
    z-index: 10; /* Ensure description stays below the title */
}

/* Hover effect for more apparent text */
.project-card:hover .description {
    background: rgba(0, 0, 0, 0.8); /* Darker background for more contrast */
    color: white; /* Ensures text remains visible */
}
